<template>
  <CRow class="w-100 justify-content-center">
    <CCol col="12" sm="8" md="6" lg="6" xl="5">
      <CCard class="table-setting-style">
        <CCardHeader>
          <h3>Налаштування сповіщення</h3>
          <CAlert v-if="alert_notify.error" style="font-size: 12px;" show color="danger">Сталася помилка, повторіть
            спробу ще раз!
          </CAlert>
          <CAlert v-if="alert_notify.success" style="font-size: 12px;" show color="success">Налаштування успішно
            збережені!
          </CAlert>
        </CCardHeader>
        <CCardBody>
          <p class="mb-3" v-if="can('service_setting_password')">
            <CLink @click="$router.push({name: `BankAppPassword`})">Налаштування сервісу</CLink>
          </p>
<!--          <p class="mb-3" v-if="can('view_edit_manager_it_rp')">-->
<!--            <CLink @click="$router.push({name:'BankEditManagerITRP'})">Редагування менеджерів ІТ РП</CLink>-->
<!--          </p>-->
          <table class="w-100">
            <tr v-if="can('notify_when_user_verified')">
              <th>Сповіщати при верифікації користувача</th>
              <td class="text-right">
                <CSwitch
                    label-off="Ні"
                    label-on="Так"
                    color="primary"
                    variant="opposite"
                    :checked.sync="notification.verification"
                />
              </td>
            </tr>
            <br>
            <tr v-if="can('notify_when_validated_applications')">
              <th>Сповіщати при валідації заявок</th>
              <td class="text-right">
                <CSwitch
                    label-off="Ні"
                    label-on="Так"
                    color="primary"
                    variant="opposite"
                    :checked.sync="notification.validation"
                />
              </td>
            </tr>
            <br v-if="can('notify_at_the_end_of_tender')">
            <tr v-if="can('notify_at_the_end_of_tender')">
              <th>Сповіщати при закінченні тендера</th>
              <td class="text-right">
                <CSwitch
                    label-off="Ні"
                    label-on="Так"
                    color="primary"
                    variant="opposite"
                    :checked.sync="notification.end_tender"
                />
              </td>
            </tr>
            <br v-if="can('notify_procurement_department_about_selection_winner')">
            <tr v-if="can('notify_procurement_department_about_selection_winner')">
              <th>Сповіщати про вибір переможця</th>
              <td class="text-right">
                <CSwitch
                    label-off="Ні"
                    label-on="Так"
                    color="primary"
                    variant="opposite"
                    :checked.sync="notification.confirm_procurement"
                />
              </td>
            </tr>
            <br v-if="can('notify_confirm_tender')">
            <tr v-if="can('notify_confirm_tender')">
              <th>Сповіщати для підтвердження тендера</th>
              <td class="text-right">
                <CSwitch
                    label-off="Ні"
                    label-on="Так"
                    color="primary"
                    variant="opposite"
                    :checked.sync="notification.confirm_tender"
                />
              </td>
            </tr>
            <br v-if="can('notify_for_escalation')">
            <tr v-if="can('notify_for_escalation')">
              <th>Сповіщати для ЕСКАЛАЦІЇ</th>
              <td class="text-right">
                <CSwitch
                    label-off="Ні"
                    label-on="Так"
                    color="primary"
                    variant="opposite"
                    :checked.sync="notification.escalation"
                />
              </td>
            </tr>
            <br v-if="can('notify_receiving_questions_from_provider')">
            <tr v-if="can('notify_receiving_questions_from_provider')">
              <th>Сповіщати про отримання питань від провайдера</th>
              <td class="text-right">
                <CSwitch
                    label-off="Ні"
                    label-on="Так"
                    color="primary"
                    variant="opposite"
                    :checked.sync="notification.feedback"
                />
              </td>
            </tr>

          </table>
        </CCardBody>
        <CCardFooter>
          <CRow class="justify-content-end">
            <CCol col sm="5">
              <CButton
                  v-if="loading"
                  class="btn-block"
                  color="primary"
                  disabled
              >
                <div class="  align-items-center">
                  <CSpinner color="default" size="sm"/>
                </div>
              </CButton>
              <CButton
                  v-else
                  class="btn-block"
                  color="primary"
                  @click="saveSettings"
              >
                Зберегти
              </CButton>
            </CCol>
          </CRow>
        </CCardFooter>
      </CCard>
    </CCol>
  </CRow>
</template>

<script>
import axios from "axios";
import {can} from "@/rules/permissions";

export default {
  name: "AdminSettings",
  data: () => {
    return {
      loading: false,
      alert_notify: {
        error: false,
        success: false
      },
      notification: {
        verification: false,
        validation: false,
        end_tender: false,
        confirm_tender: false,
        escalation: false,
        feedback: false,
        confirm_procurement: false,
      },
    }
  },
  methods: {
    can,
    getSettings() {
      let self = this;
      self.alert_notify.error = false;
      self.alert_notify.success = false;
      axios.get('/api/account/notify')
          .then(response => self.notification = response.data)
          .catch(() => {
            self.alert_notify.error = true;
            self.alert_notify.success = false;
          });
    },
    saveSettings() {
      let self = this;
      self.loading = true
      axios.put('/api/account/notify', self.notification)
          .then(function () {
            self.loading = false;
            self.alert_notify.error = false;
            self.alert_notify.success = true;
          })
          .catch(function () {
            self.loading = false;
            self.alert_notify.error = true;
            self.alert_notify.success = false;
          })
    }
  },
  mounted() {
    this.getSettings();
  }
}
</script>
